import type { FC } from "react";

export const OFTV: FC = () => {
  return (
    <svg
      width="75"
      height="44"
      viewBox="0 0 75 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.9643 11.8775L18.7334 32.0403H23.9481L31.1791 11.8775H25.9643Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M35.3915 28.1112C35.3915 30.4118 33.4993 32.2763 31.1651 32.2763C28.8309 32.2763 26.9387 30.4118 26.9387 28.1112C26.9387 25.8106 28.8309 23.9461 31.1651 23.9461C33.4993 23.9461 35.3915 25.8106 35.3915 28.1112Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.0733 11.8775C19.3767 14.3486 16.7697 16.4895 13.4668 16.0074L11.854 20.5092C19.6991 21.4469 23.2099 17.6509 25.1575 11.8775H20.0733Z"
        fill="currentColor"
      />
      <path
        d="M54.8875 27.8671L51.6848 16.2865H54.7233L57.1213 25.0125H57.6304L60.012 16.2865H63.0012L59.8149 27.8671H54.8875Z"
        fill="currentColor"
      />
      <path
        d="M51.4617 16.2865V18.9464H47.9469V27.8671H44.892V18.9464H41.3935V16.2865H51.4617Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M69.1259 2.7265H5.87409C4.15269 2.7265 2.75723 4.10642 2.75723 5.80863V38.1914C2.75723 39.8936 4.15269 41.2735 5.87409 41.2735H69.1259C70.8473 41.2735 72.2428 39.8936 72.2428 38.1914V5.80863C72.2428 4.10642 70.8473 2.7265 69.1259 2.7265ZM5.87409 0C2.62992 0 0 2.60061 0 5.80863V38.1914C0 41.3994 2.62992 44 5.87409 44H69.1259C72.3701 44 75 41.3994 75 38.1914V5.80863C75 2.60061 72.3701 0 69.1259 0H5.87409Z"
        fill="currentColor"
      />
    </svg>
  );
};
