export enum LinksWithSubMenu {
  TEAMS = "teams",
  COMPETITIONS = "competitions",
}

export const SERVER_ERROR_CODE = 500;
export const OUT_ANIMATION_DURATION = 250 + 650;

export const OFTV_URL = "https://tv.onefootball.com";
export const BETS_PTBR_URL = "https://onefootball.com/pt-br/apostas";
